import $ from "jquery";
import '../../assets/libs/bootstrap-5.0.2-dist/js/bootstrap.bundle.min.js';
import * as AOS from '../../assets/libs/aos';
import Footer from '@/components/footer/Footer'
import Header from '@/components/header/header';

import {product,apiGet} from '../../services/api'
var he = require('he');
export default {
    name: "ProductOld",
    data(){
        return{
            id: null,
            info: null,
            myHtmlCode:`<p class="bold">MaiMai</p><hr/>`
        }
    },
    methods: {
        decode(str){
            return he.decode(str);
        }
    },
    async mounted () {
        document.title = "AGC - Product";

        AOS.init();
        resize();
        function resize(){
            if(window.innerWidth<800) $('.footer').css({'position':'relative','top':'0px'});
        }
        $('#subMenu-BT').on('click',()=>{
            $('.subMenu-BG').toggle(1000);
        });
        this.id = this.$route.params.id
        this.info = await apiGet(product,{id:this.id})
        if(this.info.length) {
            this.info = this.info[0]
            this.myHtmlCode = this.decode(this.info?.detail);
            console.log(this.myHtmlCode)
            if(this.myHtmlCode.match('style="font-size: 1.4vw;"')){
                this.myHtmlCode = this.myHtmlCode.replace(/style="font-size: 1.4vw;"/g, "")
                console.log(this.myHtmlCode)
            }
        }
    },
    components: {
        Footer,Header
    }
};